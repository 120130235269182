$ = jQuery;

$(document).ready(function(){

	if ($('.page-template-mentors').length > 0){

		var argsOur = {
			slidesToScroll: 1,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 6000,
			prevArrow: '<button type="button" class="slick-prev slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to previous slide"><span class="fal fa-arrow-left text-dark"></span></button>',
			nextArrow: '<button type="button" class="slick-next slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to next slide"><span class="fal fa-arrow-right text-dark"></span></button>',
				
			responsive: [
				{
					breakpoint: 4000
					, settings: {
						slidesToShow: 1
					}
				}
				, {
					breakpoint: 992
					, settings: {
						// arrows: false
						// ,
						 slidesToShow: 1
					}
				}
				, {
					breakpoint: 768
					, settings: {
						// arrows: false
						// , 
						slidesToShow: 1
					}
				}
				, {
					breakpoint: 576
					, settings: {
						slidesToShow: 1
						, dots: true
					}
				}
			]
		};
		$('.ment-card-deck').slick(argsOur);
	
		// Toggle button on FAQ section
		$('#loadBtnMent').on('click',function(){
			if ( $('#loadBtnMent').hasClass('collapsed') ) {
				$('.panel').removeClass('d-none');
				$('#loadBtnMent').removeClass('collapsed');
			} else {
				$('.panel-last').addClass('d-none');
				$('#loadBtnMent').addClass('collapsed');
			}
		});
	
		// Listen for button click and show more/less in Our Mentors
		var myBtn = document.getElementById("loadBtnOurMent");
		myBtn.addEventListener("click", function(ev){
			console.log(ev.target.classList.contains('load-down'));
			var secondText = document.getElementById("second-msg-ment");
			var myBtn = document.getElementById("loadBtnOurMent");
	
			if ( myBtn.classList.contains('more') ) {
				myBtn.classList.remove('more');
				myBtn.classList.add('less');
				secondText.style.display = "block";
				myBtn.innerHTML = "Thank you from LPP <span class='fas fa-caret-up'></span>";
			} else {
				myBtn.classList.remove('less');
				myBtn.classList.add('more');
				secondText.style.display = "none";
				myBtn.innerHTML = "Thank you from LPP <span class='fas fa-caret-down'></span>";
			}
	
		 });
	
		 $('.about-lawyers').matchHeight();
	}

});
