// This code will hide error styling in the form after user will focus on error input fields
$ = jQuery;
$(document).on('gform_post_render', function(){
 
	var elems = document.getElementsByClassName('gfield_error');
	var el = '';
	Array.prototype.forEach.call(elems, function(el){

		// Remove margin top from all elements with error
		// Otherwise the input element will jump once it will gain focus and error styling will be removed.
		el.style.paddingTop = "0";
		el.style.paddingBottom = "0";
		el.style.maxWidth = "50%";
		el.style.marginRight = "0";
		el.style.setProperty('margin-bottom', '16px', 'important');

		var label = el.getElementsByTagName('label');
		label[0].style.marginTop = "0";

		var elms = ['input', 'textarea'];
		Array.prototype.forEach.call(elms, function(inpt){
			// If input is focused, remove error styling from it
			var input = el.getElementsByTagName(inpt);
			if ( 0 < input.length ) {
				input[0].addEventListener('focus', (event) => {
					
					// Make label text black color
					el.classList.remove("gfield_error");

					// Remove right shaddow from input
					event.target.style = "box-shadow:unset";

					// Remove max-width 50% from parent of textarea
					el.style.maxWidth = "none";

					// Hide validation_message
					var valmsg = el.getElementsByClassName("validation_message");
					valmsg[0].style.display = 'none';
					if ( 'undefined' !== typeof valmsg[1] ) {
						valmsg[1].style.display = 'none'; // Phone field has two messages
					}

					// Count all elements with gfield_error class. If there is no elements left, hide validation_message
					var err = document.getElementsByClassName('gfield_error');
					if ( err.length === 0 ) {
						var valerr = document.getElementsByClassName('validation_error');
						valerr[0].style.display = 'none';
					}
				});
			}
		});
	});
});