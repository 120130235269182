$ = jQuery;

$(document).ready(function(){
	var args = {
		slidesToScroll: 1,
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to previous slide"><span class="fal fa-arrow-left"></span></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to next slide"><span class="fal fa-arrow-right"></span></button>',
			
		responsive: [
			{
				breakpoint: 4000
				, settings: {
					slidesToShow: 4
				}
			}
			, {
				breakpoint: 992
				, settings: {
					slidesToShow: 3
				}
			}
			, {
				breakpoint: 768
				, settings: {
					arrows: false
					, slidesToShow: 2
				}
			}
			, {
				breakpoint: 576
				, settings: {
					slidesToShow: 1
					, dots: true
				}
			}
		]
	};
	$('.cand-card-deck').slick(args);

	// Toggle button on FAQ section
	$('#loadBtn').on('click',function(){
		if ( $('#loadBtn').hasClass('collapsed') ) {
			$('.panel').removeClass('d-none');
			$('#loadBtn').removeClass('collapsed');
		} else {
			$('.panel-last').addClass('d-none');
			$('#loadBtn').addClass('collapsed');
		}
		
	});
});
