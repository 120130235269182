$ = jQuery;

$(document).ready(function(){
	var args = {
		slidesToScroll: 1,
		infinite: true,
		prevArrow: '<button type="button" class="slick-prev slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to previous slide"><span class="fal fa-arrow-left"></span></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to next slide"><span class="fal fa-arrow-right"></span></button>',
			
		responsive: [
			{
				breakpoint: 4000
				, settings: {
					slidesToShow: 4
				}
			}
			, {
				breakpoint: 992
				, settings: {
					slidesToShow: 3
				}
			}
			, {
				breakpoint: 768
				, settings: {
					arrows: false
					, slidesToShow: 2
				}
			}
			, {
				breakpoint: 576
				, settings: {
					slidesToShow: 1
					, dots: true
				}
			}
		]
	};

	if ($('.empl-card-deck').length > 0){
		$('.empl-card-deck').slick(args);
	}

	var argsOur = {
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 6000,
		prevArrow: '<button type="button" class="slick-prev slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to previous slide"><span class="fal fa-arrow-left text-dark"></span></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow bg-transparent py-0 px-0 border-0" aria-label="Go to next slide"><span class="fal fa-arrow-right text-dark"></span></button>',
			
		responsive: [
			{
				breakpoint: 4000
				, settings: {
					slidesToShow: 1
				}
			}
			, {
				breakpoint: 992
				, settings: {
					// arrows: false
					// ,
					 slidesToShow: 1
				}
			}
			, {
				breakpoint: 768
				, settings: {
					// arrows: false
					// , 
					slidesToShow: 1
				}
			}
			, {
				breakpoint: 576
				, settings: {
					slidesToShow: 1
					, //dots: true
				}
			}
		]
	};

	if ($('.our-card-deck').length > 0){
		$('.our-card-deck').slick(argsOur);

	}

	// Toggle button on FAQ section
	$('#loadBtnEmpl').on('click',function(){
		if ( $('#loadBtnEmpl').hasClass('collapsed') ) {
			$('.panel').removeClass('d-none');
			$('#loadBtnEmpl').removeClass('collapsed');
		} else {
			$('.panel-last').addClass('d-none');
			$('#loadBtnEmpl').addClass('collapsed');
		}
	});

	// Listen for button click and show more/less in Message from Director
	
	var myBtn = document.getElementById("loadBtnOurEmpl");

	if (myBtn){

		myBtn.addEventListener("click", function(ev){
			console.log(ev.target.classList.contains('load-down'));
			var secondText = document.getElementById("second-msg-our");
			var myBtn = document.getElementById("loadBtnOurEmpl");
			var myBtnIcon = $('.button-icon');
	
			if ( myBtn.classList.contains('more') ) {
				myBtn.classList.remove('more');
				myBtn.classList.add('less');
				secondText.style.display = "block";
				// myBtn.innerHTML = "Thank you from LPP <spaqn class='fas fa-caret-up'></spaqn>";
				myBtnIcon.addClass('fa-caret-up');
				myBtnIcon.removeClass('fa-caret-down');
	
			} else {
				myBtn.classList.remove('less');
				myBtn.classList.add('more');
				secondText.style.display = "none";
				// myBtn.innerHTML = "Thank you from LPP <span class='fas fa-caret-down'></span>";
				myBtnIcon.removeClass('fa-caret-up');
				myBtnIcon.addClass('fa-caret-down');
			}
	
		 });
	}
});
