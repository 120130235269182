jQuery(document).ready(function($){
    

    // $(document).on('click', '.supervising-lawyers .add_list_item', function(){
    //     $('.ontario-bar-list .add_list_item').last().trigger('click');
    // });

    // gform.addAction( 'gform_list_post_item_delete', function ( item, container ) {
    
    //     if (item.closest('.form-group').hasClass('supervising-lawyers')){
    //         $('.ontario-bar-list .delete_list_item').last().trigger('click');
    //     }

    // });

   $('.supervising-lawyers .gfield_list_group').each(function(){
       var number = $(this).index()+ 1;
    $(this).append('<span class="number">'+number +'</span>')
   });

   $(document).on('click', '.supervising-lawyers .add_list_item', function(){
        $('.supervising-lawyers .number').remove();
        $('.supervising-lawyers .gfield_list_group').each(function(){
            var number = $(this).index()+ 1;
        $(this).append('<span class="number">'+number +'</span>')
        });
        $('.supervising-lawyers .gfield_list_group').last().find('input').each(function(){
            $(this).css('box-shadow', 'none');
            $(this).addClass('input-normal');
            $(this).removeClass('input-valid');
        });
    });
    gform.addAction( 'gform_list_post_item_delete', function ( item, container ) {
    
        $('.supervising-lawyers .number').remove();
        $('.supervising-lawyers .gfield_list_group').each(function(){
            var number = $(this).index()+ 1;
        $(this).append('<span class="number">'+number +'</span>')
        });

    });

    
});
jQuery = $
$(document).on('gform_post_render', function(event, form_id, current_page){
    if (form_id == 5){
        
        if ($('.supervising-lawyers').hasClass('gfield_error')){

            $('.supervising-lawyers .gfield_list_group').each(function(){

                $('input', this).each(function(index, value){
                    console.log(index);
                    //non required fields. set to normal styling
                    if (index === 5 || index === 8){
                        $(this).addClass('input-normal');
                    }
                    //add error styling
                    else if ($(this).val() == ""){
                        $(this).css('border', '1px solid #790000;');
                        $(this).css('box-shadow', 'inset 4px 0 0 0 #dc3545')
                    }
                    //no error
                    else{
                        $(this).addClass('input-valid');
                        // $(this).css('border','1px solid #ced4da !important;')
                        // $(this).css('box-shadow', 'inset 4px 0 0 0 #7ac042 !important;');
                    }
                });
            });
            

        }
    }

    $('.supervising-lawyers .gfield_list_cell').each(function(){
        var $this = $(this),
            $tr = $('tr', $this.parent().parent().prev()),
            i = $this.index();
        $('input', $this).attr('placeholder', $('th:eq('+i+')', $tr).text());		
   });

});