// Toggles menu button on mobile
// Source: https://mdbootstrap.com/docs/jquery/navigation/hamburger-menu/
$ = jQuery;
$(document).ready(function () {

	$('.first-button').on('click', function () {
		// $('.animated-icon1').toggleClass('open');
		// // Close search form when we close menu on mobile
		// if ( $('#hidden-search').hasClass('show') ) {
		// 	$('#searchHeaderBtn').trigger('click');
		// 	$('#searchHeaderBtn').removeClass('invisible');
		// }
	});

	// Hide Search icon when it's clicked
	// $('#searchHeaderBtn').on('click', function() {
	// 	$('#searchHeaderBtn').addClass('invisible');
	// });
	// // Show Search button when hide search (#closeSearchBtn) is clicked
	// $('#closeSearchBtn').on('click', function() {
	// 	$('#searchHeaderBtn').removeClass('invisible');
	// });

	$('#searchHeaderBtn').click(function(){
		$('#hidden-search').slideToggle();

		var icon = $(this).find('span');

		if (icon.hasClass('fa-search')){
			icon.addClass('fal fa-times');
			icon.removeClass('fa-search');
		}
		else{
			icon.removeClass('fal fa-times');
			icon.addClass('fa-search');
		}

	});

});