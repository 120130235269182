
jQuery(document).ready(function(){

    $('.companies-btn-toggle').click(function(){
     
        $('.companies-list').slideToggle();

        var caret = $(this).find('.caret');

        if (caret.hasClass('fa-caret-down')){
            caret.removeClass('fa-caret-down');
            caret.addClass('fa-caret-up');
        }
        else{
            caret.removeClass('fa-caret-up');
            caret.addClass('fa-caret-down');
        }
    });

    $('.twitter-widget .textwidget').addClass('card position-relative mx-0 mb-4');

    $( '.card.position-relative' ).matchHeight();
    $( '.card.position-relative img' ).on( 'load', () => {
        $( '.card.position-relative' ).matchHeight();
    });

    $( '#latest-news .card-deck' ).on( 'afterChange', function(event, slick, currentSlide, nextSlide){
        setTimeout(function(){ 
            $( '.card.position-relative' ).matchHeight();
         }, 1000);
    });

});